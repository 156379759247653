import { useEffect, useRef, useState } from 'react';
import Icon from '../components/icons/Icon'
import { Outlet, useNavigate } from 'react-router'
import Locations from './widget/components/locations';
import Dock from './widget/components/dock';
import { useAppContext } from '../context/appContext';
import { getVolum, getWidgetByKey } from '../utils/requests';
import { Spin } from 'antd';


export default function Layout({config} : {config: string}) {
    const navigate = useNavigate()
    const [selectedLocation, setselectedLocation] = useState<{name:string, path:string, network:{coll:string , id:string}} | null>(null);
    const [ifram, setifram] = useState('');
    const [value, setvalue] = useState('');
    const { setDatacenter, setVolume, rootRef } = useAppContext()
    const [loading, setloading] = useState(false);
    const [widget, setwidget] = useState<null | {networkColl: string, networkId: string, path:string}>(null);
    useEffect(() => {
    
       fetch(getWidgetByKey(config)).then(res => res.json()).then(data => setwidget(data.data)).catch(err => console.log(err))
    }, [config]);
 
    useEffect(() => {
        if(widget){
            fetch(getVolum({id:widget.networkId, coll:widget.networkColl})).then(res => res.json()).then(data => {
                if(widget.networkColl === 'datacenters'){
                    setDatacenter(data.data)
                    setVolume(null)
    
                }else{
                    setVolume(data.data)
                }
                setselectedLocation({path:widget.path, name: '', network:{coll:'', id:''}})
            }).finally(() => setloading(false))

        }else{
            setselectedLocation(null)
            setDatacenter(null)
            setVolume(null)
        }
        
    }, [widget]);

    const handleNetworkNavigation  = () => {
        navigate(`/widget/main${selectedLocation?.path}`)
    }
    const handleGridNavigation = () => {
        navigate('/widget/grid')
    }
    return (
        <div className=' fixed z-[1000] bottom-0 left-0' ref={rootRef}>
            {/* <iframe src={ifram} className='w-full h-full'></iframe> */}
            <div className=' bottom-0 left-0 flex justify-between items-end p-4'>
                
                {/* <button disabled={!selectedLocation} className='bg-skin-fill-inverted fill-skin-inverted text-skin-inverted rounded px-4 py-2 flex space-x-2.5 items-center disabled:opacity-20' onClick={() => navigate(`/widget/main${selectedLocation?.path}`)}>
                    <Icon className='icon-sm' name='TeddraLogo'/>
                    <p>Teddra -</p>
                </button> */}
                <Dock handleNetworkNavigation={handleNetworkNavigation} handleGridNavigation={handleGridNavigation} path={selectedLocation?.path}/>
                {/* <div className='flex rounded border border-main'>
                    <Locations elRef={rootRef} handleSelectLocation={handleSelectLocation} selectedLocation={selectedLocation?.name}/>

                    <input type="text" className='min-w-[300px] max-w-[400px] w-full border-none pl-2' placeholder='website' value={value} onChange={e => setvalue(e.target.value)} />
                    <button className='bg-skin-fill-inverted text-skin-inverted rounded-r px-4 disabled:opacity-20' disabled={!selectedLocation} onClick={() => {
                        setifram(value)
                    }}>Ok</button>
                </div> */}
            
                <Outlet/>
            

            </div>

        </div>

  )
}
